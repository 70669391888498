<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Pemesanan Ruangan</b>
            </div>
            <br/>
            <div class="col-md-2 col-12">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="belum_dispo">Belum Ditindaklanjuti</option>
                <option value="sudah_dispo">Sudah Ditindaklanjuti</option>
                <option value="pencarian_khusus">Pencarian Khusus</option>
              </select>
            </div>
            
              <div class="container">
                <div class="row justify-content-md-center">
                  <div class="col-md-12">
                    <form method="post" @submit.prevent="changeDt">
                      <div class="form-body">
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Nama Ruangan" type="text" class="form-control" v-model="nama_ruangan" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Nama OPD" type="text" class="form-control" v-model="nama_unit" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row justify-content-md-center">
                          <div class="col-md-9 col-sm-10">
                            <div class="row">
                              <div class="form-group" style="width: 378px">
                                <input placeholder="Pencarian Kapasitas Ruangan" type="text" class="form-control" v-model="kapasitas_ruangan" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-actions">
                        <div class="row justify-content-md-center">
                          <div class="col-md-7 pl-5">
                            <button
                              type="submit"
                              class="btn btn-success"
                              title="Simpan"
                            >
                              Cari</button
                            >&nbsp;
                            <button
                              type="button"
                              @click="resetInputs()"
                              class="btn btn-inverse"
                              title="Reset"
                            >
                              Batal
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onEdit="doEdit"
              :onPemesanan="doPemesanan"
              :onDetail="doDetail"
              @onDelete="doDelete"
              :forbidDetail="forbidRead"
              :forbidDelete="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    return {
      headers: {
        nama_ruangan: {
          label: "Nama Ruangan",
          sortable: true,
        },
        unit_room: {
          label: "OPD Ruangan",
          sortable: true,
        },
        telepon: {
          label: "Telepon",
          sortable: true,
        },
        kapasitas_ruangan: {
          label: "Kapasitas Ruangan",
          sortable: true,
        },
        fasilitas_ruangan: {
          label: "Fasilitas Ruangan",
          sortable: true
        },
        // status_pemesanan: {
        //   label: "Status Pemesanan",
        //   sortable: true,
        // },
      },
    };
  },
  computed: {
    items() {
      return this.$store.state.meeting.items
        ? this.$store.state.meeting.items.items
        : [];
    },
    filters() {
      return this.$store.state.meeting.items
        ? this.$store.state.meeting.items.filters
        : {};
    },
    state() {
      return this.$store.state.meeting;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.meeting) {
        return !this.$store.state.profile.permissions.meeting.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.meeting) {
        return !this.$store.state.profile.permissions.meeting.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.meeting) {
        return !this.$store.state.profile.permissions.meeting.delete;
      }
      return false;
    },
    isMeeting() {
      return true;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
    };
    this.$store.commit("meeting/STATE", state);
    this.get(state);
  },
  methods: {
    handleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan,
        cari: this.cari,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("meeting/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("meeting/getMeeting", val);
    },
    resetInputs() {
      this.cari = "";
      this.nama_ruangan = "";
      this.nama_unit = "";
      this.kapasitas_ruangan = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan,
        cari: this.cari,
        asal: this.asal,
      };
      this.$store.commit("meeting/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    resetInput() {
      this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.nomor_agenda = "";
      this.periode_surat = "";
      this.perihal = "";
      this.nomor_surat = "";
      this.nama_ruangan = "";
      this.nama_unit = "";
      this.kapasitas_ruangan = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("meeting/STATE", state);
      this.get(state);
      this.$children[0].$refs.table.reload();
    },
    filterData: function () {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        nama_ruangan: this.nama_ruangan,
        nama_unit: this.nama_unit,
        kapasitas_ruangan: this.kapasitas_ruangan
      };
      this.$store.commit("meeting/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.$store.dispatch("meeting/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("meeting/onEdit", val);
    },
    doPemesanan(val) {
      this.$store.dispatch("meeting/onPemesanan", val);
    },
    doDelete(val) {
      this.$store.dispatch("meeting/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("meeting/STATE", state);
      this.get(state);
    },
  },
};
</script>
